import React from 'react'
import Layout from '../components/layout'

import { graphql } from 'gatsby'
import ImageList from '@material-ui/core/ImageList'
import ImageListItem from '@material-ui/core/ImageListItem'

import {
  Box,
  makeStyles,
  Typography,
  Grid,
  ListItem,
  ListItemText,
  Card,
  CardContent,
} from '@material-ui/core'
import ContactMailIcon from '@material-ui/icons/ContactMail'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: '100%',
    height: 600,
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  description: {
    margin: theme.spacing(2),
    fontSize: '1.2em',
  },
  price: {
    color: '#10a3c2',
    marginTop: theme.spacing(3),
  },
  listItem: {
    backgroundColor: '#f8f8f8',
  },
  card: {
    maxWidth: 425,
  },
}))

export default function ProjectDetails({ data }) {
  const classes = useStyles()

  const {
    agents_info,
    property_description,
    adress,
    images,
    info,
    amenities,
    features,
  } = data.caHousesJson

  const { domain } = data.site.siteMetadata

  return (
    <Layout>
      <Box>
        <Typography variant='h4'>{adress.street}</Typography>
      </Box>
      <Box className={classes.root} mt={4}>
        <ImageList className={classes.imageList} cols={1} rowHeight='auto'>
          {images.map((image) => (
            <ImageListItem key={image} key={image}>
              <img src={image} alt='houses' />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      <Grid container alignItems='center' justifyContent='space-between'>
        <Typography className={classes.price} variant='h3'>
          {info.Price}
        </Typography>
        <Typography variant='h5'>
          {info.Year_Home_Built
            ? `Home Year Build: ${info.Year_Home_Built}`
            : ''}
        </Typography>
      </Grid>
      {/* Property Details */}
      <Box mt={5}>
        <Box mb={3}>
          <Typography variant='h5'>PROPERTY DETAILS</Typography>
        </Box>
        <Grid mt={2} container alignItems='center' spacing={3}>
          {Object.entries(info).map(
            (item) =>
              item[1] && (
                <Grid item md={3} key={item}>
                  <ListItem elevation={0} className={classes.listItem}>
                    <ListItemText>{`${item[0].replace(
                      '_',
                      ' '
                    )} : ${item[1].replace('_', '-')}`}</ListItemText>
                  </ListItem>
                </Grid>
              )
          )}
        </Grid>
      </Box>
      {/* Amenities*/}
      <Box mt={5}>
        <Box mb={3}>
          <Typography variant='h5'>AMENITIES</Typography>
        </Box>
        <Grid mt={2} container alignItems='center' spacing={3}>
          {amenities.map((item) => (
            <Grid item md={3} key={item}>
              <ListItem elevation={0} className={classes.listItem}>
                <ListItemText>{item}</ListItemText>
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* features*/}
      {/* {features && (
        <Box mt={5}>
          <Box mb={3}>
            <Typography variant='h5'>BUILDING/PROPERTY DETAILS</Typography>
          </Box>
          <Grid mt={2} container alignItems='center' spacing={3}>
            {Object.entries(features).map(
              (item) =>
                item[1] && (
                  <Grid item md={3} key={item}>
                    <ListItem elevation={0} className={classes.listItem}>
                      <ListItemText>{`${item[0].replaceAll(
                        '_',
                        ' '
                      )} : ${item[1].replaceAll('_', '-')}`}</ListItemText>
                    </ListItem>
                  </Grid>
                )
            )}
          </Grid>
        </Box>
      )} */}

      {/* Description */}
      <Box mt={5}>
        <Typography variant='h4'>Description:</Typography>
        <Typography variant='body1' className={classes.description}>
          {property_description}
        </Typography>
      </Box>

      {/* AGENTS INFO */}
      <Box mt={4}>
        <Box mb={3}>
          <Typography variant='h5'>Contact Us</Typography>
        </Box>

        <Grid container>
          {agents_info.map((agent) => (
            <Grid item md={6} sm={12} key={agent.name}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography>{agent.name}</Typography>
                  <Typography>O: {agent.phone_number}</Typography>
                  <Typography>
                    Email:
                    {agent.name.split(' ').join('') + '@' + domain}
                  </Typography>
                  <ContactMailIcon />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query HouseInfo($slug: String) {
    caHousesJson(id: { eq: $slug }) {
      adress {
        city
        state
        street
        zip
      }
      featuredImg {
        childImageSharp {
          gatsbyImageData
        }
      }
      property_description
      amenities
      features {
        lot_description
        cooling
        Pet_Policy
        Parking
        Location_View_Type
        Construction_Type
        Community
      }
      images
      info {
        Baths
        Bedrooms
        Half_Baths
        Interior
        Listing_ID
        MONTHLY_MAINTENANCE_CC
        Price
        Property_Type
        Schools
        Year_Home_Built
      }
      agents_info {
        name
        phone_number
        photo
      }
    }
    site {
      siteMetadata {
        domain
      }
    }
  }
`
